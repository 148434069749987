
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Category } from '@/interfaces/models/Category';
import { Article } from '@/interfaces/models/Article';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Permission } from '@/enums/Permission';

@Component
export default class SalesFilter extends Vue {
  @Prop({ type: Array, default: () => [] }) public categories!: Category[];
  @Prop({ type: Array, default: () => [] }) public articles!: Article[];
  @Prop({ type: Array, default: () => [] }) public labels!: VenueLabel[];

  public selectedCategories: string[] = [];
  public selectedArticles: string[] = [];
  public selectedLabel: string | null = null;

  get selection() {
    return {
      article: this.selectedArticles,
      category: this.selectedCategories,
      venueLabel: this.selectedLabel,
    };
  }

  get venueLabelsWithEmpty() {
    return [{ name: this.$t('common.noSelection'), slug: null }, ...this.labels];
  }

  @Emit('change')
  public onLabelChanged() {
    return {
      article: [],
      category: [],
      venueLabel: this.selectedLabel,
    };
  }

  @Watch('selectedLabel')
  @Emit('categories')
  public resetCategoriesOnLabelChanged() {
    this.selectedArticles = [];
    this.selectedCategories = [];

    return this.selectedCategories;
  }

  @Watch('selectedCategories')
  @Emit('categories')
  public onCategoriesChange() {
    if (this.selectedCategories.length === 0) {
      this.selectedArticles = [];
    }
    return this.selectedCategories;
  }

  @Emit('change')
  public reset() {
    this.selectedArticles = [];
    this.selectedCategories = [];
    this.selectedLabel = null;

    return this.selection;
  }
}
