
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import SalesItem from '@/interfaces/models/SalesItem';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import { TableOptions } from '@/interfaces/components/TableOptions';

@Component
export default class SalesTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: SalesItem[];
  @Prop({ type: Number, required: true }) public total!: number;

  public headers: ColumnHeader[] = headers;
  public options: TableOptions = {};

  get tableHeaders() {
    return this.headers.map((h: ColumnHeader) => ({ ...h, text: this.$t(h.text) }));
  }

  @Emit('change')
  public mounted() {
    this.options.page = 1;

    return this.options;
  }

  @Emit('change')
  public resetPage() {
    this.options.page = 1;

    return this.options;
  }
}
