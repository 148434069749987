
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import SalesTable from '@/components/sales/SalesTable.vue';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { Article } from '@/interfaces/models/Article';
import VWrapper from '@/components/shared/VWrapper.vue';
import SalesFilter from '@/components/sales/SalesFilter.vue';
import SalesItem from '@/interfaces/models/SalesItem';
import { Category } from '@/interfaces/models/Category';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import Filter from '@/interfaces/api/Filter';
import SalesLoader from '@/components/sales/SalesLoader.vue';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { AxiosResponse } from 'axios';
import StatisticsApiService from '@/api/http/StatisticsApiService';
import { toUrl } from '@/util/helper';
import moment from 'moment';
import { Permission } from '@/enums/Permission';

const sales = namespace('sales');
const venue = namespace('venue');
const venueLabel = namespace('venueLabel');
const foodcard = namespace('foodcard');

@Component({
  components: { SalesLoader, VRangePicker, SalesFilter, SalesTable, VWrapper },
})
export default class Sales extends Vue {
  get selectableDateTypes() {
    return [
      { value: 'createdAt', text: this.$t('sales.ui.createdAt') },
      { value: 'orderAt', text: this.$t('sales.ui.orderAt') },
    ];
  }

  public $refs!: {
    table: SalesTable & { resetPage: () => void };
  };

  public articles: Array<Partial<Article>> = [];
  public selectedDateType: string = 'createdAt';

  @venue.State('active') public venue!: Venue;

  @venueLabel.State('items') public venueLabels!: VenueLabel[];
  @venueLabel.Action('fetch') public getVenueLabels!: any;

  @foodcard.State('categories') public categories!: Category[];
  @foodcard.Action('setCategoryFilter') public setCategoryFilter!: any;
  @foodcard.Action('fetchCategories') public getCategories!: any;

  @sales.State('items') public sales!: SalesItem[];
  @sales.State('filter') public filter!: Filter;
  @sales.State((state) => state.pagination.total) public total!: number;
  @sales.Action('fetch') public getSales: any;
  @sales.Action('setFilter') public setFilter: any;

  public async reloadSales() {
    if (this.venue && this.venue._id) {
      this.$startLoading('sales');
      if (this.$can(Permission.LABEL_VIEW)) {
        await this.getVenueLabels();
      }
      await this.getSales();
      this.$stopLoading('sales');
    }
    this.$stopLoading('venue');
  }

  public created() {
    if (!this.venue) {
      this.$startLoading('venue');
    }
  }

  public async mounted(): Promise<void> {
    if (this.venue && this.venue._id) {
      this.setFilter({ ...this.filter, venue: this.venue._id, page: 1 });
      this.setCategoryFilter({ venue: this.venue._id });
      this.$startLoading('category');
      await this.getCategories();
      this.$stopLoading('category');
    }
  }

  public async onCategoriesChange(categories: string[]) {
    this.articles = [];
    if (this.venue && this.venue._id) {
      this.$startLoading('article');
      const api: FoodcardApiService = new FoodcardApiService();
      const res: AxiosResponse<Array<Partial<Article>>> = await api.getArticleNames({
        categories,
        venue: this.venue._id,
      });
      this.articles = res.data;
      this.$stopLoading('article');
    }
  }

  public onChange(key: 'filter' | 'range' | 'table' | 'dateType', data: any): void {
    if (key === 'table') {
      const { sortBy, sortDesc, page, itemsPerPage } = data;
      const direction: string = sortDesc && sortDesc[0] ? 'desc' : 'asc';
      let limit: number = 0;
      let pageNumber: number = page;
      if (itemsPerPage === -1) {
        limit = 0;
        pageNumber = 0;
      } else {
        limit = itemsPerPage;
      }
      this.setFilter({
        ...this.filter,
        limit,
        direction,
        sort: sortBy[0],
        page: pageNumber,
        dateType: this.selectedDateType,
      });
      this.reloadSales();
    } else if (key === 'filter') {
      this.setFilter({ ...this.filter, ...data });
      this.$refs.table.resetPage();
    } else if (key === 'range') {
      this.setFilter({ ...this.filter, ...data });
      this.$refs.table.resetPage();
    } else if (key === 'dateType') {
      this.setFilter({ ...this.filter, dateType: data });
      this.$refs.table.resetPage();
    }
  }

  public exportCsv() {
    this.$startLoading('salesCsv');
    const api: StatisticsApiService = new StatisticsApiService();
    api
      .getSalesCsv({
        venue: this.filter.venue,
        dateType: this.filter.dateType,
        from: this.filter.from,
        to: this.filter.to,
        venueLabel: this.filter.venueLabel,
        lang: this.$i18n.locale,
      })
      .then((res: AxiosResponse) => toUrl(res.data, `sales-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('salesCsv');
      });
  }

  @Watch('venue')
  public async onVenueChange() {
    if (this.venue && this.venue._id) {
      if (this.$refs && this.$refs.table) {
        this.$refs.table.resetPage();
      }
      this.setFilter({ ...this.filter, venue: this.venue._id, page: 1 });
      this.setCategoryFilter({ venue: this.venue._id });
      this.$startLoading('category');
      await this.getCategories();
      this.$stopLoading('category');
      this.reloadSales();
    }
  }
}
